import React, { useState, useEffect } from 'react';

function Cell(props) {
    const isConfirmed = props.selectedCellId === props.cell_id && props.selectedSubgridId === props.subgrid_id;
    //console.log(props);
    let takenClass = "";
    if(props.cellData === 'X'){
        takenClass = " --x";
    }else if(props.cellData === 'O'){
        takenClass = " --o";
    }

    const oSvg = (
        <svg className='marker -o' viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7 13.5a6.5 6.5 0 1 0 13 0 6.5 6.5 0 0 0-13 0ZM13.5 0C6.044 0 0 6.044 0 13.5S6.044 27 13.5 27 27 20.956 27 13.5 20.956 0 13.5 0Z" fill="#ffffff"/>
        </svg>
    );

    const xSvg = (
        <svg className='marker -x' viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5 7.816 5.684 0H0v5.684L7.816 13.5 0 21.316V27h5.684l7.816-7.816L21.316 27H27v-5.684L19.184 13.5 27 5.684V0h-5.684L13.5 7.816Z" fill="#ffffff"/>
        </svg>
    );

    let lastMoveClass = "";
    if(props.lastMove[0] === props.subgrid_id && props.lastMove[1] === props.cell_id){
        lastMoveClass = " --last-move";
    }


    return (
        <button
            className={`cell ${isConfirmed ? '--confirm' : ''} ${takenClass} ${lastMoveClass}`}
            data-cell={props.cell_id}
            data-subgrid={props.subgrid_id}
            onClick={() => {
                if (props.available && props.cellData == "") {
                    props.handleCellSelect(props.cell_id, props.subgrid_id);
                }
            }}
        >
            {props.cellData === 'X' ? xSvg : props.cellData === 'O' ? oSvg : ''}
            <div className='avaiable-indicator'></div>
            <div className='confirm-indicator'></div>
            <div className='last-move-indicator'></div>
        </button>
    );
}


function Subgrid(props) {

    const oSvg = (
        <svg className='complete-marker -o' viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7 13.5a6.5 6.5 0 1 0 13 0 6.5 6.5 0 0 0-13 0ZM13.5 0C6.044 0 0 6.044 0 13.5S6.044 27 13.5 27 27 20.956 27 13.5 20.956 0 13.5 0Z" fill="#ffffff"/>
        </svg>
    );

    const xSvg = (
        <svg className='complete-marker -x' viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5 7.816 5.684 0H0v5.684L7.816 13.5 0 21.316V27h5.684l7.816-7.816L21.316 27H27v-5.684L19.184 13.5 27 5.684V0h-5.684L13.5 7.816Z" fill="#ffffff"/>
        </svg>
    );

    const isConfirmed = props.confirmedSubgrid === props.subgrid_id;


    if(typeof props.cellData.data === 'string'){
        let completedClass = "";
        if(props.cellData.data === 'X'){
            completedClass = " --x";
        }else if(props.cellData.data === 'O'){
            completedClass = " --o";
        }
        return (
            <div
                className={`subgrid ${props.available ? '--available' : ''} ${isConfirmed ? '--confirm' : ''} ${completedClass}`}
                data-subgrid={props.subgrid_id}
            >
                {props.cellData.data === 'X' ? xSvg : props.cellData.data === 'O' ? oSvg : ''}
            </div>
        );
    }else{
        return (
            <div
                className={`subgrid ${props.available ? '--available' : ''} ${isConfirmed ? '--confirm' : ''}`}
                data-subgrid={props.subgrid_id}
            >
                {props.cellData.data.map((cellId,key) => (
                    <Cell
                        key={key.toString()}
                        cell_id={key.toString()}
                        subgrid_id={props.subgrid_id}
                        available={props.available}
                        handleCellSelect={props.handleCellSelect}
                        selectedCellId={props.selectedCellId}
                        selectedSubgridId={props.selectedSubgridId}
                        cellData={cellId} //{props.cellData}
                        lastMove={props.lastMove}
                    />
                ))}
            </div>
        );
    }
}

function Grid(props) {
    const subgridIds = props.cellData ? Object.keys(props.cellData) : ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i'];
    if(props.isLoading){
        return (
            <div className="grid-loading">
                <div className="ellipsis">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                </div>
            </div>
        );
    }else{
        let playerSideClass = "";
        if(props.playerSide === 'X'){
            playerSideClass = " --x-blue";
        }else if(props.playerSide === 'O'){
            playerSideClass = " --o-blue";
        }

        return (
            <div className={`grid ${playerSideClass}`}>
                {subgridIds.map((id) => (
                    <Subgrid
                        key={id}
                        subgrid_id={id}
                        available={props.cellData ? props.cellData[id].available : true}
                        confirm={false}
                        handleCellSelect={props.handleCellSelect}
                        selectedCellId={props.selectedCellId}
                        selectedSubgridId={props.selectedSubgridId}
                        confirmedSubgrid={props.confirmedSubgrid}
                        cellData={props.cellData ? props.cellData[id] : null}
                        lastMove={props.lastMove}
                    />
                ))}
            </div>
        );
    }
}


function Game(props) {
    const [selectedCell, setSelectedCell] = useState({ cellId: null, subgridId: null });
    const [confirmedSubgrid, setConfirmedSubgrid] = useState(null);
    const [actionsVisible, setActionsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [gameData, setGameData] = useState({});
    const [gameInfo, setGameInfo] = useState(['Loading', 'Please wait']);
    const [lastMove, setLastMove] = useState(null);
    const [isPlayerTurn, setIsPlayerTurn] = useState(false);
    const [playerSide, setPlayerSide] = useState(null);

    const fetchGameData = () => {
        const playerCode = localStorage.getItem('player_code');
        if (playerCode) {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', `${props.apiRoot}/players/${playerCode}`, true);
            xhr.setRequestHeader("Content-Type", "application/json");

            xhr.onload = () => {
                if (xhr.status === 200) {
                    const response = JSON.parse(xhr.responseText);
                    //console.log(response);
                    if (response.game.status == 1) {
                        setGameData(response.game.board);
                        setPlayerSide(response.side);

                        setLastMove([response.game.last_move[0], response.game.last_move[1]]);

                        if (response.game.turn === response.side) {
                            setGameInfo(['Your Turn', 'Select an available cell']);
                            setIsPlayerTurn(true);
                        } else {
                            setGameInfo(['Opponent\'s Turn', 'Please wait']);
                            setIsPlayerTurn(false);
                        }
                        setIsLoading(false);
                    } else if(response.game.status == 2){
                        props.setCurrentView('game-end');
                    }else {
                        alert('Something went bad!')
                        //window.location.reload();
                    }
                } else if (xhr.status === 404) {
                    localStorage.removeItem('player_code');
                    props.setCurrentView('menu');
                }
            };

            xhr.send();
        } else {
            props.setCurrentView('menu');
        }
    };

    useEffect(() => {
        fetchGameData();

        let intervalId;
        if (!isPlayerTurn) {
            intervalId = setInterval(fetchGameData, 1000);
        }else{
            setConfirmedSubgrid(null);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isPlayerTurn]);

    const handleCellSelect = (cellId, subgridId) => {
        setSelectedCell({ cellId, subgridId });
        const cellToSubgridMap = [
            'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i'
        ];
        setConfirmedSubgrid(cellToSubgridMap[cellId]);
        setActionsVisible(true);
    };

    const handleConfirm = () => {
        const playerCode = localStorage.getItem('player_code');
        if (!playerCode) {
            //console.log('Player code not found');
            return;
        }

        const move = `${selectedCell.subgridId}${selectedCell.cellId}`;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${props.apiRoot}/move`, true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status >= 200 && xhr.status < 400) {
                    setSelectedCell({ cellId: null, subgridId: null });
                    //setConfirmedSubgrid(null);
                    setActionsVisible(false);
                    setIsPlayerTurn(false); // It's no longer the player's turn
                } else {
                    console.error('Error confirming move:', xhr.statusText);
                }
            }
        };
        xhr.send(JSON.stringify({ move, player_code: playerCode }));
    };

    const handleCancel = () => {
        setSelectedCell({ cellId: null, subgridId: null });
        setConfirmedSubgrid(null);
        setActionsVisible(false);
    };

    const handleGameExit = () => {
        localStorage.removeItem('player_code');
        props.setCurrentView('menu');
    };

    return (
        <>
            <header className="header__game">
                <img src="/assets/logo.svg" alt="Logo" />
                <button onClick={handleGameExit}></button>
            </header>
            <div className="game">
                <div className={`info ${isPlayerTurn ? '--emphasis' : ''}`}>
                    <span className="heading">{gameInfo[0]}</span>
                    <span className="body">{gameInfo[1]}</span>
                </div>
                <Grid
                    handleCellSelect={handleCellSelect}
                    selectedCellId={selectedCell.cellId}
                    selectedSubgridId={selectedCell.subgridId}
                    confirmedSubgrid={confirmedSubgrid}
                    cellData={gameData}
                    lastMove={lastMove}
                    isLoading={isLoading}
                    playerSide={playerSide}
                />
                <div className={`actions ${actionsVisible ? '--visible' : ''}`}>
                    <button onClick={handleConfirm} className="--primary"><span>Confirm</span></button>
                    <button onClick={handleCancel}><span>Cancel</span></button>
                </div>
            </div>
        </>
    );
}


export default Game;
