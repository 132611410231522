import React, { useState, useEffect } from 'react';
function Loading(props) {

    useEffect(() => {
        //console.log('loading');
        //if player_code is not set, then redirect to menu
        const playerCode = localStorage.getItem('player_code');
        //console.log(playerCode);
        if (!playerCode) {
            //console.log('Go to menu');
            props.setCurrentView('menu');
            props.setIsLoading(false);

        } else {
            //console.log('Send request to check player_code');
            //check if player_code is valid
            const xhr = new XMLHttpRequest();
            xhr.open('GET', `${props.apiRoot}/players/${playerCode}`, true);
            xhr.setRequestHeader("Content-Type", "application/json");

            xhr.onload = () => {
                if (xhr.status === 200) {
                    //console.log(xhr.responseText);
                    if(JSON.parse(xhr.responseText).game.status == 0){
                        props.setCurrentView('menu-start');
                        props.setIsLoading(false);
                    }else if(JSON.parse(xhr.responseText).game.status == 1){
                        props.setCurrentView('game');
                        props.setIsLoading(false);
                    }else if(JSON.parse(xhr.responseText).game.status == 2){
                        props.setCurrentView('game-end');
                        props.setIsLoading(false);
                    }

    
                } else if (xhr.status === 404) {
                    //console.log(xhr.responseText);
                    //clear the player_code from local storage
                    localStorage.removeItem('player_code');
                    //redirect to menu
                    props.setCurrentView('menu');
                    props.setIsLoading(false);
                    //setIsLoading(false);
                }
            };
    
            xhr.send();
        }
    }, []);

    return (
        <>
            <header className="header">
                <img src="/assets/logo.svg" alt="Logo" />
            </header>
            <div className="loading">
                <span>Loading</span>
                <div className="ellipsis">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                </div>
            </div>
        </>
    );
}

export default Loading;