import React, { useState, useEffect } from 'react';
import Loading from './views/Loading';
import Game from './views/Game';
import GameEnd from './views/GameEnd';
import Menu from './views/Menu';
import MenuJoin from './views/MenuJoin';
import MenuStart from './views/MenuStart';

function PwaNotice() {
	const handleCloseClick = () => {
		const pwaNotice = document.querySelector('.pwa-notice');
		pwaNotice.style.display = 'none';
	};

	return (
		<div className='pwa-notice'>
			<div className='icon'></div>
			<div className='content'>
				<div className='title'>Install OX9?</div>
				<div className='description'>Tap the Share icon, and then <br/>'Add to Home Screen' to install.</div>
			</div>
			<button className='close' onClick={handleCloseClick}></button>
		</div>
	);
}

function App() {
	const [currentView, setCurrentView] = useState('menu');
	const [isLoading, setIsLoading] = useState(true);
    const [isIOS, setIsIOS] = useState(false);
    const [isPwaOnIOS, setIsPwaOnIOS] = useState(false);

	let apiRoot = '';
	//const apiRoot = 'https://ox9-api.dandyliontech.uk';

	//if the app is running from ox9.dandyliontech.uk, use the production API
	if (window.location.hostname === 'ox9game.com') {
		apiRoot = 'https://api.ox9game.com';
	}else{
		apiRoot = 'http://192.168.1.116:8010/proxy';
	}

    // iOS and PWA detection
    useEffect(() => {
        const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        setIsIOS(isIOSDevice);

        const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator.standalone);
        setIsPwaOnIOS(isIOSDevice && isInStandaloneMode);
    }, []);

	const renderView = () => {
		if (isLoading) {
			return <Loading setIsLoading={setIsLoading} setCurrentView={setCurrentView} apiRoot={apiRoot} />;
		}else{
			switch (currentView) {
				case 'menu':
					return <Menu setCurrentView={setCurrentView} />;
				case 'menu-join':
					return <MenuJoin setCurrentView={setCurrentView} apiRoot={apiRoot} />;
				case 'menu-start':
					return <MenuStart setCurrentView={setCurrentView} apiRoot={apiRoot} />;
				case 'game':
					return <Game setCurrentView={setCurrentView} apiRoot={apiRoot} />;
				case 'game-end':
					return <GameEnd setCurrentView={setCurrentView} apiRoot={apiRoot} />;
				default:
					return null;
			}
		}
	};

	return (
		<>
		<div className={`app ${isPwaOnIOS ? '--pwa' : '--no-pwa'}`}>
			{renderView()}
		</div>
		{isIOS && !isPwaOnIOS && <PwaNotice />}
		</>
	);
}

export default App;
