import React, { useState, useRef } from 'react';

function MenuJoin(props) {
    const inputRefs = useRef([]);
    const [isInputComplete, setInputComplete] = useState(false);

    const handleStartButton = () => {
        //props.setCurrentView('game');

        const joinCode = inputRefs.current.map(input => input.value).join('');
        //console.log(joinCode);

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', `${props.apiRoot}/games/${joinCode}`, true);
        xhr.setRequestHeader("Content-Type", "application/json");

        xhr.onload = () => {
            if (xhr.status === 200) {
                localStorage.setItem('player_code', JSON.parse(xhr.responseText).player_code);
                props.setCurrentView('game');

                //console.log(xhr.responseText);
            } else if (xhr.status === 404) {
                alert('Invalid join code');
                //clear the inputs
                inputRefs.current.forEach(input => input.value = '');
            }
        };

        xhr.send();
    }

    const handleBackButton = () => {
        props.setCurrentView('menu');
    }

    const handleInputChange = (index, event) => {
        const value = event.target.value;
        if (value.length === 1 && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }

        updateInputCompleteStatus();
    }

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && event.target.value.length === 0 && index > 0) {
            inputRefs.current[index - 1].focus();
            inputRefs.current[index - 1].value = '';
            updateInputCompleteStatus();
        }
    }

    const updateInputCompleteStatus = () => {
        const allFilled = inputRefs.current.every(input => input && input.value.length === 1);
        setInputComplete(allFilled);
    }

    return (
        <>
            <header className="header">
                <img src="/assets/logo.svg" alt="Logo" />
            </header>
            <div className="menu">
                <div className="info">
                    <span className="heading">Enter Join Code</span>
                    <span className="body">Ask your opponent for the Join Code and enter it below.</span>
                </div>
                <div className="join-code">
                    <div className="input">
                        {[...Array(6)].map((_, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength={1}
                                ref={ref => inputRefs.current[index] = ref}
                                onChange={event => handleInputChange(index, event)}
                                onKeyDown={event => handleKeyDown(index, event)}
                                autoFocus={index === 0}
                            />
                        ))}
                    </div>
                    <button 
                        className={`--primary ${isInputComplete ? '' : '--disabled'}`} 
                        onClick={handleStartButton} 
                        disabled={!isInputComplete}>
                            <span>Join Game</span>
                    </button>
                </div>

                <button onClick={handleBackButton}><span>Go Back</span></button>
            </div>
        </>
    );
}

export default MenuJoin;
