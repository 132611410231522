import React, { useState, useEffect } from 'react';

function MenuStart(props) {
    const [joinCode, setJoinCode] = useState('');
    const [loading, setLoading] = useState(true);
    const [gameInfo, setGameInfo] = useState('Share the code with your opponent to start.');

    const handleStartButton = () => {
        props.setCurrentView('game');
    }

    const handleBackButton = () => {
        localStorage.removeItem('player_code');
        props.setCurrentView('menu');
    }

    useEffect(() => {
        //console.log('begin game load');

        const playerCode = localStorage.getItem('player_code');
        if (playerCode) {
            //console.log('player_code is set');
            const xhr = new XMLHttpRequest();
            xhr.open('GET', `${props.apiRoot}/players/${playerCode}`, true);
            xhr.setRequestHeader("Content-Type", "application/json");

            xhr.onload = () => {
                if (xhr.status === 200) {
                    setJoinCode(JSON.parse(xhr.responseText).game.code);
                    setLoading(false);
                    setGameInfo('Share the code with your opponent to start.');
                } else if (xhr.status === 404) {
                    localStorage.removeItem('player_code');
                    props.setCurrentView('menu');
                }
            };

            xhr.send();
        } else {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${props.apiRoot}/games`, true);
            xhr.setRequestHeader("Content-Type", "application/json");

            xhr.onload = () => {
                if (xhr.status === 200) {
                    //console.log(xhr.responseText);
                    const response = JSON.parse(xhr.responseText);
                    setJoinCode(response.game_code);
                    setLoading(false);
                    setGameInfo('Share the code with your opponent to start.');
                    localStorage.setItem('player_code', response.player_code);
                } else {
                    //console.log(xhr.responseText);
                }
            };

            xhr.send();
        }

        // Set up an interval to check the game status every second
        const intervalId = setInterval(() => {
            //console.log('check game status');
            const playerCode = localStorage.getItem('player_code');
            //console.log(playerCode);
            if (playerCode) {
                //console.log('player_code is set, request game status from API');
                const xhr = new XMLHttpRequest();
                xhr.open('GET', `${props.apiRoot}/players/${playerCode}`, true);
                xhr.setRequestHeader("Content-Type", "application/json");

                xhr.onload = () => {
                    if (xhr.status === 200) {
                        const response = JSON.parse(xhr.responseText);
                        //console.log(response);
                        if (response.game.status == 1) {
                            clearInterval(intervalId);
                            props.setCurrentView('game');
                        }
                    } else if (xhr.status === 404) {
                        clearInterval(intervalId);
                        localStorage.removeItem('player_code');
                        props.setCurrentView('menu');
                    }
                };

                xhr.send();
            }else{
                //console.log('player_code is not set');
            }
        }, 1000);

        // Clean up the interval on unmount
        return () => clearInterval(intervalId);
    }, [props, joinCode]);

    return (
        <>
            <header className="header">
                <img src="/assets/logo.svg" alt="Logo" />
            </header>
            <div className="menu">
                <div className="info">
                    <span className="heading">Start Game</span>
                    <span className="body">{gameInfo}</span>
                </div>
                <div className="join-code">
                    <div className={`code ${loading ? '--loading' : ''}`}>
                        <span>{joinCode[0]}</span>
                        <span>{joinCode[1]}</span>
                        <span>{joinCode[2]}</span>
                        <span>{joinCode[3]}</span>
                        <span>{joinCode[4]}</span>
                        <span>{joinCode[5]}</span>
                    </div>
                </div>
                <button onClick={handleBackButton}><span>Cancel</span></button>
            </div>
        </>
    );
}

export default MenuStart;
