function Menu(props) {
    const handleStartButton = () => {
        props.setCurrentView('menu-start');
    }
    const handleJoinButton = () => {
        props.setCurrentView('menu-join');
    }

    return (
        <>
            <header className="header">
                <img src="/assets/logo.svg" alt="Logo" />
            </header>
            <div className="menu">
                <button onClick={handleStartButton} className="--primary"><span>Start game</span></button>
                <button onClick={handleJoinButton}><span>Join game</span></button>
            </div>
        </>
    );
}

export default Menu;